import {request} from '@/common/admin/request.js'

export const site = {
	upload(data) {
		return request.post('/backend/upload/create', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			timeout: 60 * 20 * 1000
		})
	},
	clean(data) {
		return request.post('/backend/site/clean', data);
	}
}

export const attachment = {
	all(params) {
		return request.get('/backend/attachment/all', {params})
	},
}

export const auth = {
	login(data) {
		return request.post('/backend/auth/login', data)
	},
	getLoginInfo() {
		return request.post('/backend/auth/getLoginInfo')
	}
}

export const dashboard = {
	stat(params) {
		return request.get('/backend/dashboard/stat', {params})
	},
	check(params) {
		return request.get('/backend/dashboard/check', {params})
	},
}

export const config = {
	get(params) {
		return request.get('/backend/config/get', {params})
	},
	all(params) {
		return request.get('/backend/config/all', {params})
	},
	create(data) {
		return request.post('/backend/config/create', data)
	},
	update(data) {
		return request.post('/backend/config/update', data)
	},
	delete(data) {
		return request.post('/backend/config/delete', data)
	},
	sort(data) {
		return request.post('/backend/config/sort', data)
	},
	import(data) {
		return request.post('/backend/config/import', data)
	},
	collect() {
		return request.get('/backend/config/collect')
	}
}

export const admin = {
	get(params) {
		return request.get('/backend/admin/get', {params})
	},
	all(params) {
		return request.get('/backend/admin/all', {params})
	},
	create(data) {
		return request.post('/backend/admin/create', data)
	},
	delete(data) {
		return request.post('/backend/admin/delete', data)
	},
	sort(data) {
		return request.post('/backend/admin/sort', data)
	},
	import(data) {
		return request.post('/backend/admin/import', data)
	},
	update(data) {
		return request.post('/backend/admin/update', data)
	},
	password(data) {
		return request.post('/backend/admin/password', data)
	}
}

export const role = {
	get(params) {
		return request.get('/backend/role/get', {params})
	},
	all(params) {
		return request.get('/backend/role/all', {params})
	},
	create(data) {
		return request.post('/backend/role/create', data)
	},
	delete(data) {
		return request.post('/backend/role/delete', data)
	},
	sort(data) {
		return request.post('/backend/role/sort', data)
	},
	import(data) {
		return request.post('/backend/role/import', data)
	},
}

export const sensitive = {
	get(params) {
		return request.get('/backend/sensitive/get', {params})
	},
	all(params) {
		return request.get('/backend/sensitive/all', {params})
	},
	create(data) {
		return request.post('/backend/sensitive/create', data)
	},
	delete(data) {
		return request.post('/backend/sensitive/delete', data)
	},
	sort(data) {
		return request.post('/backend/sensitive/sort', data)
	},
	import(data) {
		return request.post('/backend/sensitive/import', data)
	},
}

export const article = {
	get(params) {
		return request.get('/backend/article/get', {params})
	},
	all(params) {
		return request.get('/backend/article/all', {params})
	},
	create(data) {
		return request.post('/backend/article/create', data)
	},
	delete(data) {
		return request.post('/backend/article/delete', data)
	},
	sort(data) {
		return request.post('/backend/article/sort', data)
	},
	import(data) {
		return request.post('/backend/article/import', data)
	},
}

export const user = {
	get(params) {
		return request.get('/backend/user/get', {params})
	},
	all(params) {
		return request.get('/backend/user/all', {params})
	},
	create(data) {
		return request.post('/backend/user/create', data)
	},
	delete(data) {
		return request.post('/backend/user/delete', data)
	},
	sort(data) {
		return request.post('/backend/user/sort', data)
	},
	import(data) {
		return request.post('/backend/user/import', data)
	},
	start(data) {
		return request.post('/backend/user/start', data)
	},
	pause(data) {
		return request.post('/backend/user/pause', data)
	},
}
