import {getGlobalUserId} from "@/common/page.js";
import {anonymous} from "@/api/smarket.js";
import {setCookie} from "@/common/tool.js";

export default [
	{
		path: "/",
		component: () => import('@/layout/Pages.vue'),
		children: [
			{
				path: '/',
				component: () => import('@/pages/Index.vue'),
				meta: {title: '首页'},
			}
		],
		beforeEnter: async (to, from, next) => {
			const globalUserId = getGlobalUserId()
			if(!globalUserId){
				const res = await anonymous.getId({
					clientBrand:'',
					clientType:'',
					clientVersion:'',
					clientIP:'',
					browseInfo:{
						url:window.location.href,
						userAgent:navigator.ua,
					}
				})
				setCookie('globalUserId','"'+res+'"')
			}
			next();
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'Error',
		component: () => import('@/pages/Error.vue'),
		meta: {title: '错误'},
	}
]
