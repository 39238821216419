import {getCookie, inArray} from "@/common/tool.js";
import {adminStore} from "@/store/admin/index.js";
import {cloneDeep} from "lodash";
import {auth} from "@/api/admin/index.js";
import {ElMessage} from "element-plus";

const mergeMenu = (menu = [], role) => {
    if (inArray(role, '*')) {
        return menu;
    }
    const list = [];
    menu.map((item, index) => {
        let one = {};
        if ((item.children && item.children.length) || inArray(role, item.path)) {
            one = cloneDeep(item);
        }
        if (item.children && item.children.length) {
            one.children = mergeMenu(item.children, role)
        }
        if (one.path || (one.children && one.children.length)) {
            list.push(one)
        }
    })
    return list;
}
const getMenu = (role_menu, to) => {
    const store = adminStore()
    const role = role_menu?.split(',') || [];
    const menu = mergeMenu(store.sys_menu, role);
    if (menu.length <= 0) {
        return false;
    }
    store.setMenu(menu);
    const list = [];
    treeForeach(menu, (node) => {
        if (node.path) {
            list.push(node)
        }
    })
    const index = list.findIndex((item) => {
        return item.path === to.path
    })
    const next = list[index || 0];
    const fixed = list?.[0];
    fixed.fixed = true;
    store.setTags([
        fixed
    ])
    return next;
}
const treeForeach = (tree, fun) => {
    tree.forEach((data) => {
        fun(data)
        data.children && treeForeach(data.children, fun)
    })
}

export default [
    {
        path: "/admin",
        component: () => import('@/layout/admin/Admin.vue'),
        children: [
            {
                path: '/admin',
                component: () => import('@/pages/admin/dashboard/Index.vue'),
                meta: {title: '控制台'},
            },
            {
                path: '/admin/article',
                component: () => import('@/pages/admin/article/Index.vue'),
                meta: {title: '文章管理'},
            },
            {
                path: '/admin/user',
                component: () => import('@/pages/admin/user/Index.vue'),
                meta: {title: '用户管理'},
            },
            {
                path: '/admin/admin',
                component: () => import('@/pages/admin/admin/Index.vue'),
                meta: {title: '管理员'},
            },
            {
                path: '/admin/role',
                component: () => import('@/pages/admin/role/Index.vue'),
                meta: {title: '角色管理'},
            },
            {
                path: '/admin/config',
                component: () => import('@/pages/admin/config/Index.vue'),
                meta: {title: '配置管理'},
            },
            {
                path: '/admin/config/config',
                component: () => import('@/pages/admin/config/Config.vue'),
                meta: {title: '系统配置'},
            },
            {
                path: '/admin/:pathMatch(.*)*',
                name: 'Error',
                component: () => import('@/pages/admin/Error.vue'),
                meta: {title: '错误'},
            }
        ],
        beforeEnter: async (to, from, next) => {
            if (!getCookie('authorization')) {
                next('/admin/auth/login')
                return false;
            }
            const store = adminStore()
            if (!store.menu.length) {
                try {
                    const {admin, role} = await auth.getLoginInfo()
                    const store = adminStore()
                    store.setAdminInfo(admin)
                    const fixed = getMenu(role?.menu, to);
                    if (!fixed) {
                        throw new Error('没有菜单权限')
                    } else {
                        next(fixed.path)
                    }
                } catch (error) {
                    next('/admin/auth/login')
                    ElMessage.error(error.message)
                }
            } else {
                next();
            }
        }
    },
    {
        path: '/admin/auth/login',
        component: () => import('@/pages/admin/auth/Login.vue'),
        meta: {title: '登录'},
    },
]
