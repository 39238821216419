import {request} from '@/common/request.js'

export const wechat = {
    jssdk(params) {
        return request.get('/api/wechat/jssdk', {params})
    }
}

export const wxapp = {
    openid(data) {
        return request.post('/api/wxapp/openid', data)
    }
}

export const config = {
    collect() {
        return request.get('/api/config/collect')
    }
}

export const auth = {
    login(data) {
        return request.post('/api/auth/login', data)
    }
}

export const views = {
    log(data) {
        return request.post('/api/view/log', data)
    }
}

export const user = {
    info(params) {
        return request.get('/api/user/info', {params})
    }
}

export const article = {
    get(params) {
        return request.get('/api/article/get', {params})
    }
}

export const book = {
    create(data) {
        return request.post('/api/book/create', data)
    },
    get(params) {
        return request.get('/api/book/get', {params})
    }
}