export const site_config = {
	title: import.meta.env.VITE_APP_TITLE,
	api: import.meta.env.DEV ? import.meta.env.VITE_APP_API_HOST : import.meta.env.VITE_APP_API_HOST,
	smarket: import.meta.env.DEV ? import.meta.env.VITE_APP_SMARKET_HOST : import.meta.env.VITE_APP_SMARKET_HOST,
	cookie_prefix: import.meta.env.DEV ? import.meta.env.VITE_APP_COOKIE_PREFIX : import.meta.env.VITE_APP_COOKIE_PREFIX,
}
export const ext_config = {
	file: ".pdf,.ppt,.doc,.docx,.word,.xlxs,.xls",
	image: ".jpg,.jpeg,.png,.gif,.svg,.tiff",
	video: ".mp4,.mov,.ogg",
}

export const smarket_config = {
	customFormId:"2407290215967983",
	instanceId:941515,
	tenantId:100377,
}