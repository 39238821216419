import {Toast, Dialog, setToastDefaultOptions,Calendar } from "vant"
import 'vant/lib/toast/style';
import 'vant/lib/dialog/style';
import 'vant/lib/calendar/style';

setToastDefaultOptions('loading', {
    forbidClick: true,
    duration: 0,
});

export const installVant = (Vue) => {
    Vue.use(Toast);
    Vue.use(Dialog);
    Vue.use(Calendar);
}
