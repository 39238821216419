import AImage from "@/components/common/Image.vue"
import ALink from "@/components/common/Link.vue"
import APage from "@/components/common/Page.vue"
import AEmpty from "@/components/common/Empty.vue"
import AVideo from "@/components/common/Video.vue"

export const installComponentsViews = (Vue) => {
    Vue.component('AImage', AImage);
    Vue.component('ALink', ALink);
    Vue.component('APage', APage);
    Vue.component('AEmpty', AEmpty);
    Vue.component('AVideo', AVideo);
}
