import {createApp} from 'vue'
import App from '@/App.vue'
import {router} from "@/router/index.js"
import {pinia} from "@/store/pinia.js"
import {initPlugins} from "@/plugins/index.js";
import {initDirective} from "@/directive/index.js";

const Vue = createApp(App)
Vue.use(pinia).use(router)


initPlugins(Vue);
initDirective(Vue);

Vue.mount('#app')

