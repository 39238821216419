import {request} from '@/common/smarket.js'
import {smarket_config} from "@/config"
import {getGlobalUserId} from "@/common/page.js";

export const anonymous = {
    getId:(data)=>{
        return request.post('/anonymous/getId',data)
    }
}

export const common = {
    jsonParamsGet:(data)=>{
        data.globalUserId = getGlobalUserId();
        data.customFormId = smarket_config.customFormId;
        return request.post('/api/common/jsonParams/get',data)
    }
}

export const customForm = {
    checkRegistration:(data)=>{
        data.globalUserId = getGlobalUserId();
        data.customFormId = smarket_config.customFormId;
        return request.post('/customForm/checkRegistration',data)
    },
    action:(data)=>{
        data.globalUserId = getGlobalUserId();
        data.customFormId = smarket_config.customFormId;
        return request.post('/customForm/action',data)
    },
    checkRepeatable:(data)=>{
        data.globalUserId = getGlobalUserId();
        data.customFormId = smarket_config.customFormId;
        return request.post('/customForm/checkRepeatable',data)
    }
}