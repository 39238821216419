import {defineStore} from 'pinia'

export const adminStore = defineStore('admin', {
	state: () => {
		return {
			admin_info: {},
			system_config: {},
			tags: [
				{title: "控制台", path: "/admin", fixed: true}
			],
			sys_menu:[
				{title: "控制台", icon: 'Odometer', path: '/admin'},
				{title: "文章管理", icon: 'Tickets', path: '/admin/article'},
				{title: "用户管理", icon: 'Tickets', path: '/admin/user'},
				{
					title: "系统管理", icon: 'Tickets',
					children: [
						{title: "系统配置", path: '/admin/config/config'},
						{title: "配置管理", path: '/admin/config'},
						{title: "角色管理", path: '/admin/role'},
						{title: "管理员", path: '/admin/admin'},
					]
				}
			],
			menu:[]
		}
	},
	actions: {
		setAdminInfo(admin_info) {
			this.admin_info = admin_info;
		},
		setSystemConfig(system_config) {
			this.system_config = system_config;
		},
		setTags(tags) {
			this.tags = tags;
		},
		setMenu(menu){
			this.menu = menu;
		}
	}
})
