import { auth, views } from "@/api/index.js";
import { site_config } from "@/config/index.js";
import { getCookie, setCookie, getUUID } from "@/common/tool.js";

export const getGlobalUserId = () => {
	const globalUserId = getCookie('globalUserId')
	return globalUserId ? globalUserId.replace('/"/g', '') : ''
}

export const doViewsLogin = async () => {
	return new Promise(async (resolve, reject) => {
		const token = getCookie('token');
		if (!token) {
			const uuid = getUUID();
			const res = await auth.login({
				source: 1,
				uuid: uuid,
			})
			setCookie('token', res.token);
			resolve(res.token);
		} else {
			resolve(token);
		}
	})
}

export const createViewLog = (params = {}) => {
	doViewsLogin().then((res) => {
		const data = Object.assign({}, {
			source: 1,
			title: document.title,
			ua: navigator.userAgent,
			url: window.location.href,
			tc: getCookie('tc') || ''
		}, params);
		views.log(data).then(() => { })
	})
}

export const setTitle = (title) => {
	document.title = title || site_config.title;
}
