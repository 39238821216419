import axios from 'axios'
import {site_config} from "@/config/index.js"

const requestInstance = axios.create({
    timeout: 60 * 1000,
})

requestInstance.interceptors.request.use(config => {
    config.baseURL = site_config.smarket;
    config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json; charset=utf-8';
    config.headers['Accept'] = '*/*';
    return config
}, error => {
    return Promise.reject(error)
})

requestInstance.interceptors.response.use((response) => {
        const res = response.data;
        switch (res?.body?.result) {
            case 0:
                return Promise.resolve(res.body.content);
            default:
                const message = `${res.desc || 'Error'}`
                return Promise.reject({code:res?.body?.result, message: message});
        }
    },
    async (error) => {
        const message = error.message;
        const response = error?.response;
        return Promise.reject({code: response.status, message: message})
    }
)

export const request = requestInstance;
