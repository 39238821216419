import axios from 'axios'
import {ElMessage} from "element-plus"
import {site_config} from "@/config/index.js"
import {getCookie} from "@/common/tool";
import {useRoute} from "vue-router";

const requestInstance = axios.create({
	timeout: 60 * 1000,
})

requestInstance.interceptors.request.use(config => {
	config.baseURL = site_config.api;
	config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json; charset=utf-8';
	config.headers['Authorization'] = getCookie('authorization');
	return config
}, error => {
	return Promise.reject(error)
})

requestInstance.interceptors.response.use(
	response => {
		let message;
		let res = response.data;
		switch (res.code) {
			case 1:
				return Promise.resolve(res.data);
			default:
				message = `${res.msg || 'Error'}`
				if(useRoute()){
					ElMessage.error({
						message:message,
					})
				}
				return Promise.reject({code: res.code, message: message});
		}
	},
	error => {
		let message = error.message;
		let response = "";
		if (error && error.response) {
			response = error.response;
			let status = response.status;
			let statusText = response.statusText;
			let path = "";
			if (error.response.config) {
				path = response.config.baseURL + response.config.url;
			}
			switch (status) {
				case 500:
					message = `${statusText} : ${path}`;
					break;
				case 403:
					if (getCookie('authorization')) {
						setCookie('authorization', '')
						ElMessage.error('登录状态已失效，请重新登录~');
						router.replace('/admin/auth/login').then()
					}
					break;
				default:
					message = `${error.message || 'Error'} : ${path}`
					break;
			}
		}
		if (getCookie('authorization')) {
			ElMessage.error(message)
		}
		return Promise.reject({code: response.status, message: message})
	}
)

export const request = requestInstance;
